import api from '../services/api'
import Helper from './helper'

class ProductService {
  get(p, s) {
    return api.get(`/p?${Helper.getPageQuery(p, s)}`)
  }

  create(row) {
    return api.post('/p', row)
  }

  update(row) {
    return api.put('/p/' + row.id, row)
  }

  delete(row) {
    return api.delete('/p/' + row.id)
  }
}

export default new ProductService()
