<template>
  <div>
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-form :data="filter" @submit="loadData">
          <vxe-form-item>
            <template v-slot>
              <vxe-input
                v-model="filter.identifier"
                type="number"
                :clearable="true"
                placeholder="搜索筒数"
              ></vxe-input>
            </template>
          </vxe-form-item>

          <vxe-form-item>
            <template v-slot>
              <vxe-button type="submit" status="primary">查询</vxe-button>
            </template>
          </vxe-form-item>
        </vxe-form>
      </template>
    </vxe-toolbar>

    <vxe-table
      border
      resizable
      row-key
      show-overflow
      highlight-hover-row
      ref="xTable"
      :data="tableData.rows"
      :loading="loading"
    >
      <vxe-table-column type="seq" width="60"></vxe-table-column>
      <vxe-table-column field="product" title="产品"></vxe-table-column>
      <vxe-table-column field="identifier" title="筒数"></vxe-table-column>
      <vxe-table-column field="color" title="颜色"></vxe-table-column>
      <vxe-table-column field="size" title="尺码"></vxe-table-column>
      <vxe-table-column field="count" title="数量"></vxe-table-column>
      <vxe-table-column
        field="updated"
        title="修改时间"
        formatter="dateTime"
      ></vxe-table-column>
      <vxe-table-column title="操作" width="100" show-overflow>
        <template v-slot="{ row }">
          <vxe-button
            type="text"
            icon="fa fa-tasks"
            @click="preparePrint(row)"
          ></vxe-button>
        </template>
      </vxe-table-column>
    </vxe-table>

    <vxe-pager
      perfect
      :current-page.sync="tableData.page"
      :page-size.sync="tableData.pageSize"
      :total="tableData.totalResult"
      @page-change="pageChange"
    >
    </vxe-pager>

    <ProductionDetailPrint ref="productionDetailModal"></ProductionDetailPrint>
  </div>
</template>

<script>
import ProductionDetailService from '../services/production-detail.service'
import ProductService from '../services/product.service'
import ProductionDetailPrint from '../components/ProductionDetailPrint'

export default {
  components: {
    ProductionDetailPrint
  },
  data() {
    return {
      loading: false,
      submitLoading: false,
      tableData: {},
      selectRow: null,
      showEdit: false,
      products: [],
      selectedProduct: '',
      productsOptionProps: { value: 'id', label: 'title' },
      filter: {}
    }
  },
  async mounted() {
    this.loading = true
    try {
      const response = await ProductionDetailService.get()
      this.tableData = response.data
      const productResponse = await ProductService.get(1, 10)
      this.products = productResponse.data.rows
    } catch {
      this.tableData = {}
    }
    this.loading = false
  },
  methods: {
    async pageChange({ currentPage, pageSize }) {
      this.loading = true
      try {
        const response = await ProductionDetailService.get(
          currentPage,
          pageSize
        )
        this.tableData = response.data
      } catch {
        this.tableData = {}
      }
      this.loading = false
    },
    async loadData() {
      if (this.filter.identifier < 0) {
        this.$XModal.message({ message: '请输入正确的筒数', status: 'error' })
        return
      }

      try {
        this.loading = true
        const response = await ProductionDetailService.get(1, 10, this.filter)
        this.tableData = response.data
      } catch {}

      this.loading = false
    },
    async selectProduct({ value }) {
      this.loading = true
      try {
        const response = await ProductionDetailService.get(1, 10, value)
        this.tableData = response.data
      } catch {
        this.tableData = {}
      }
      this.loading = false
    },
    async preparePrint(row) {
      this.$refs.productionDetailModal.showModal(row.id)
      // console.log(response.data)
    }
  }
}
</script>
