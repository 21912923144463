import api from '../services/api'
import Helper from './helper'

class ProductionDetailService {
  get(p, s, filter) {
    if (filter !== undefined && filter.identifier > 0) {
      return api.get(`/pd?${Helper.getPageQuery(p, s)}&i=${filter.identifier}`)
    }
    return api.get(`/pd?${Helper.getPageQuery(p, s)}`)
  }

  details(id) {
    return api.get('pd/' + id)
  }
}

export default new ProductionDetailService()
