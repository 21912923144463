<template>
  <div>
    <b-modal
      id="model-process"
      size="xl"
      dialog-class="printContent"
      :hide-footer="true"
    >
      <template #modal-header="{ close }">
        <h4>
          {{ productionDetail.title }}
          <span> 每页打印</span>
          <vxe-input type="number" v-model="pageBreak"></vxe-input>
          <span>个 </span>
          <span>总共打印个数：</span>
          <vxe-input type="number" v-model="printNum"></vxe-input>
          <button class="btn btn-light" v-print="'#printContent'">打印</button>
        </h4>
        <!-- <b-button size="sm" @click="checkProcess">检查</b-button> -->
        <b-button size="sm" @click="close()"> 关闭 </b-button>
      </template>
      <div id="printContent">
        <div v-for="n in parseInt(printNum / 4 + 1)" :key="n">
          <b-row>
            <b-col
              cols="3"
              v-for="m in parseInt(4 * n <= printNum ? 4 : printNum % 4)"
              :key="m"
            >
              <b-card body-class="p-1">
                <div class="d-flex flex-row">
                  <vue-qrcode
                    :margin="2"
                    :scale="2"
                    :value="productionDetail.id"
                  />
                  <div class="ml-2 text-left">
                    <div>
                      <h5>
                        {{ productionDetail.procedure }}
                      </h5>
                    </div>
                    <div>
                      <small>{{ productionDetail.product }}</small>
                    </div>
                    <div>
                      <small class="font-weight-light"
                        >第{{ productionDetail.identifier }}筒
                      </small>
                      <a
                        class="d-none"
                        :href="
                          `${backendUrl}/Ep/Create?pd=` +
                          productionDetail.productionDetailId +
                          `&pp=` +
                          productionDetail.productProcedureId
                        "
                        >ep</a
                      >
                    </div>
                    <div>
                      <b-card-text>
                        <small
                          >{{ productionDetail.count }}件
                          {{ productionDetail.color }}
                          {{ productionDetail.size }}
                        </small>
                      </b-card-text>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <div v-if="(n * 4) % pageBreak == 0" class="newPage"></div>
        </div></div
    ></b-modal>
  </div>
</template>

<script>
import ProductionDetailService from '../services/production-detail.service'
import VueQrcode from 'vue-qrcode'

export default {
  props: [],
  components: {
    VueQrcode
  },
  data() {
    return {
      productionDetail: {},
      prints: [],
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      pageBreak: 20,
      printNum: 20
    }
  },
  methods: {
    async showModal(id) {
      this.$bvModal.show('model-process')
      try {
        const response = await ProductionDetailService.details(id)
        this.productionDetail = response.data
      } catch {}
    }
  }
}
</script>

<style scoped>
/deep/ .newPage {
  page-break-after: always;
}
/deep/ .card-deck .card {
  width: 220px;
  flex: none;
  margin-right: 0;
  margin-left: 0;
}

/deep/ .card-deck .card-body {
  padding: 0.75 rem;
}

@media print {
  /deep/ .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
}
</style>
